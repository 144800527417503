// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2021-blanket-js": () => import("./../../../src/pages/2021-blanket.js" /* webpackChunkName: "component---src-pages-2021-blanket-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-body-pillow-js": () => import("./../../../src/pages/body-pillow.js" /* webpackChunkName: "component---src-pages-body-pillow-js" */),
  "component---src-pages-canada-special-js": () => import("./../../../src/pages/canada-special.js" /* webpackChunkName: "component---src-pages-canada-special-js" */),
  "component---src-pages-duvet-js": () => import("./../../../src/pages/duvet.js" /* webpackChunkName: "component---src-pages-duvet-js" */),
  "component---src-pages-eye-pillow-js": () => import("./../../../src/pages/eye-pillow.js" /* webpackChunkName: "component---src-pages-eye-pillow-js" */),
  "component---src-pages-google-js": () => import("./../../../src/pages/google.js" /* webpackChunkName: "component---src-pages-google-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-january-special-js": () => import("./../../../src/pages/january-special.js" /* webpackChunkName: "component---src-pages-january-special-js" */),
  "component---src-pages-senior-special-js": () => import("./../../../src/pages/senior-special.js" /* webpackChunkName: "component---src-pages-senior-special-js" */),
  "component---src-pages-shopping-js": () => import("./../../../src/pages/shopping.js" /* webpackChunkName: "component---src-pages-shopping-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-white-blanket-js": () => import("./../../../src/pages/white-blanket.js" /* webpackChunkName: "component---src-pages-white-blanket-js" */)
}

